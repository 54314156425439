/* 
@tailwind base;
@tailwind utilities;
@tailwind components; */

@font-face {
    font-family: 'Dongo Outline';
    src: url('./fonts/Dongo-Outline.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Dongo Regular';
    src: url('./fonts/Dongo-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}


.font-dongo-outline {
    font-family: 'Dongo Outline', sans-serif;
}

.font-dongo-regular {
    font-family: 'Dongo Regular', sans-serif;
}

.font-IBM-sans {
    font-family: "IBM Plex Sans", sans-serif;
}

.bgTopMobile {
    width: calc(100% - 1.25rem - 1.25rem);
    margin: 1.25rem 1.25rem;
}

.bg-btn-connect-wallet {
    background: rgb(0, 212, 255);
    background: linear-gradient(90deg, rgba(0, 212, 255, 1) 0%, rgba(69, 114, 255, 1) 46%);
}

.text-dongo {
    color: #fff;
    -webkit-text-stroke: 9px #221F50;
}

.text-dongo-stroke-7 {
    color: #fff;
    -webkit-text-stroke: 7px #221F50;
}

.text-dongo-before {
    background-image: linear-gradient(180.99deg, #B7BDE2 25.56%, #9195F0 83.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-coming {
    color: #fff;
    -webkit-text-stroke: 12px #5200FF;
}

.text-coming-before {
    background-image: linear-gradient(180.99deg, #A1C4FD 25.56%, #C2E9FB 83.65%, #E0EAFC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer {
    background: #2E1F40;
}

.bg-linear-purple {
    background: linear-gradient(180.99deg, #9195F0 25.56%, #675EED 83.65%);
}

.text-menu {
    font-family: 'Dongo Regular', sans-serif;
    /* font-family: 'Dongo Outline', sans-serif; */
    font-size: 32px;
    margin-bottom: 0;
    color: #fff;
    -webkit-text-fill-color: #fff;
    font-weight: 500;
    -webkit-text-stroke: 7px #221F50;
    paint-order: stroke fill;

    /* text-shadow: -3px 5px 0 black; */
}

@media (min-width: 1024px) {
    .text-menu {
        margin-bottom: 1.3rem;
        font-size: 64px;
        -webkit-text-stroke: 12px #221F50;
    }

    .text-coming {
        -webkit-text-stroke: 18px #5200FF;
    }
}

.text-shadow-border {
    /* text-shadow: -3px -5px 0 black,
        -1px -5px 0 black,
        -2px 5px 0 black,
        3px 5px 0 black,
        4px -5px 0 black,
        -4px -5px 0 black,
        -3px 5px 0 black,
        4px 5px 0 black,
        -4px 5px 0 black; */
    -webkit-text-stroke: 12px #221F50;
}

.button-shadow {
    background: #F0B90B;
    box-shadow: 0px 76px 21px rgba(57, 46, 4, 0.01), 0px 49px 20px rgba(57, 46, 4, 0.06), 0px 28px 17px rgba(57, 46, 4, 0.2), 0px 12px 12px rgba(57, 46, 4, 0.34), 0px 3px 7px rgba(57, 46, 4, 0.39);
    border-radius: 49.0909px;
}

.text-hover-pink:hover {
    /* background: #FF7FEB; */
    -webkit-text-fill-color: #FF7FEB;
}

.text-menu-footer {
    font-family: 'Dongo Regular', sans-serif;
    /* font-family: 'Dongo Outline', sans-serif; */
    font-size: 24px;
    margin-bottom: 0;
    color: #fff;
    -webkit-text-fill-color: #fff;
    font-weight: 500;
    -webkit-text-stroke: 7px #221F50;
    paint-order: stroke fill;

    margin-bottom: 0.8rem;
}

@media (min-width: 1024px) {
    .text-menu-footer {
        margin-bottom: 1.3rem;
        font-size: 34px;
        -webkit-text-stroke: 6px #221F50;
    }
}

.listSocialFooter {
    /* padding-top: 6.5rem; */
}

.listSocialFooter img {
    width: inherit;
    max-width: inherit;

}

@media (min-width: 1024px) {
    .listSocialFooter {
        padding-top: 6.5rem;
    }

    .listSocialFooter img {
        width: 70px;
    }

}

.text-blockchain-footer,
.text-blockchain-footer-before {
    font-family: 'Dongo Regular', sans-serif;
    /* font-family: 'Dongo Outline', sans-serif;  */
    font-size: 30px;
    margin-bottom: 0;
}

.text-blockchain-footer {
    color: #fff;
    -webkit-text-stroke: 4px #221F50;
    position: relative;
}

.text-blockchain-footer-before {
    background-image: linear-gradient(180.99deg, #B7BDE2 25.56%, #9195F0 83.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 33px;
}

@media (min-width: 1024px) {

    .text-blockchain-footer,
    .text-blockchain-footer-before {
        font-size: 26px;
    }

    .text-blockchain-footer {
        height: 50px;
        -webkit-text-stroke: 6px #221F50;
    }

    .text-blockchain-footer-before {
        height: 29px;
    }
}

/* text-blockchain */
.text-blockchain,
.text-blockchain-before {
    font-family: 'Dongo Regular', sans-serif;
    /* font-family: 'Dongo Outline', sans-serif;  */
    font-size: 30px;
    margin-bottom: 0;
}

.text-blockchain {
    color: #fff;
    -webkit-text-stroke: 4px #221F50;
    position: relative;
}

.text-blockchain-before {
    background-image: linear-gradient(180.99deg, #B7BDE2 25.56%, #9195F0 83.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 33px;
}

@media (min-width: 1024px) {

    .text-blockchain,
    .text-blockchain-before {
        font-size: 44px;
    }

    .text-blockchain {
        height: 50px;
        -webkit-text-stroke: 6px #221F50;
    }

    .text-blockchain-before {
        height: 50px;
    }
}

/* end text-blockchain */

/* text-Dongo-medium */
.text-Dongo-medium {
    font-family: 'Dongo Regular', sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: -10px;
}

/* text-IBM-medium */
.text-IBM-medium {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0;
}

/* text-Dongo-medium */
.text-IBM-small {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 0;
}

/* text-welcome */
.text-welcome,
.text-welcome-before {
    font-family: 'Dongo Regular', sans-serif;
    font-size: 30px;
    margin-bottom: 0;
}

.text-welcome {
    color: #fff;
    -webkit-text-stroke: 5px #221F50;
}

.text-welcome-before {
    background-image: linear-gradient(180.99deg, #B7BDE2 25.56%, #9195F0 83.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 33px;
}

.font-54 {
    font-size: 54px;
    color: linear-gradient(180.99deg, #B7BDE2 25.56%, #9195F0 83.65%);
}

.bg-gray {
    background: #B7BDE2;
}

.game_image {
    width: 690px;
    height: 626px;
}

@media (min-width: 1024px) {

    .text-welcome,
    .text-welcome-before {
        font-size: 67px;
    }

    .text-welcome {
        -webkit-text-stroke: 10px #221F50;
    }

    .text-welcome-before {
        height: 74px;
    }
}

/* end text-welcome */

.text-before-footer {
    font-family: 'Dongo Regular', sans-serif;
    font-size: 17px;
    color: #fff;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 18%;
    text-align: center;
    width: 100%;
}

@media (min-width: 1024px) {
    .text-before-footer {
        font-size: 64px;
    }
}

/* text-meet-dogs */
.text-meet-dogs,
.text-meet-dogs-before {
    font-family: 'Dongo Regular', sans-serif;
    font-size: 24px;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.text-meet-dogs {
    -webkit-text-stroke: 4px #221F50;
    color: #fff
}

.text-meet-dogs-before {
    background-image: linear-gradient(180.99deg, #B7BDE2 25.56%, #9195F0 83.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 28px;
}

@media (min-width: 1024px) {

    .text-meet-dogs,
    .text-meet-dogs-before {
        font-size: 84px;
        margin-bottom: 0rem;
    }

    .text-meet-dogs {
        -webkit-text-stroke: 12px #221F50;
    }

    .text-meet-dogs-before {
        height: 94px;
    }
}

/* text-meet-dogs-section */
.text-meet-dogs-section {
    /* font-family: 'IBM Plex Sans'; */
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */
    text-align: center;
    letter-spacing: -0.01em;
}

@media (max-width: 1024px) {
    .text-meet-dogs-section {
        font-size: 20px;
    }
}

/* end text-meet-dogs-section */

.text-meet-dogs-section-2 {
    /* font-family: 'IBM Plex Sans'; */
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    /* line-height: 150%; */
    /* or 28px */
    text-align: center;
    letter-spacing: -0.01em;
    color: #000000;
    padding: 0 6px;
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1024px) {
    .text-meet-dogs-section-2 {
        font-size: 14px;
    }
}


.text-faq,
.text-faq-before {
    font-family: 'Dongo Regular', sans-serif;
    font-size: 54px;
    /* padding-top: 3.5rem;  */
    margin-top: 3.5rem;
    width: 210px;
}

.text-faq {
    color: #fff;
    -webkit-text-stroke: 8px #221F50;
}

.text-faq-before {
    background-image: linear-gradient(180.99deg, #B7BDE2 25.56%, #9195F0 83.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* height: inherit; */
    height: 152px;
}



.linear-yellow {
    background-image: linear-gradient(180.99deg, #FFCC2B 25.56%, #FF9D2B 83.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (min-width: 1024px) {

    .text-faq,
    .text-faq-before {
        display: block;
        font-size: 51px;
        /* -webkit-text-stroke: 3px #221F50; */
        padding-top: 0rem;
        width: 110%;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: inherit;
    }

    .text-faq {
        -webkit-text-stroke: 8px #221F50;

    }

    .text-faq-before {
        height: 60px;
    }

    .text-tap,
    .text-tap-before {
        display: block;
        font-size: 51px;
        /* -webkit-text-stroke: 3px #221F50; */
        padding-top: 0rem;
        width: 110%;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: inherit;
    }

    .text-tap {
        -webkit-text-stroke: 8px #221F50;

    }

    .text-tap-before {
        height: 60px;
    }
}

.text-aboutUs,
.text-aboutUs-before {
    font-family: 'Dongo Regular', sans-serif;
    font-size: 30px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    height: 30px;
}

.text-aboutUs {
    color: #fff;
    -webkit-text-stroke: 5px #221F50;
}

.text-aboutUs-before {
    background-image: linear-gradient(180.99deg, #B7BDE2 25.56%, #9195F0 83.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 38px;
}

@media (min-width: 1024px) {

    .text-aboutUs,
    .text-aboutUs-before {
        font-size: 84px;
        margin-bottom: 4rem;
        padding-top: 0rem;
        height: 84px;
    }

    .text-aboutUs {
        -webkit-text-stroke: 12px #221F50;
    }

    .text-aboutUs-before {}

    .fz-84 {
        font-size: 84px;
    }
}

.text-learnMore,
.text-learnMore-before {
    font-family: 'Dongo Regular', sans-serif;
    font-size: 15px;
    margin-bottom: 2rem;
    height: 17px;
}

.text-learnMore {
    -webkit-text-stroke: 3px #221F50;
    color: #fff
}

.text-learnMore-before {
    background-image: linear-gradient(180deg, #E2B7B7 0%, #F091CA 79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.time-box {
    border: 0.9px solid;
    border-radius: 7.17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 700;
}

@media (min-width: 1024px) {

    .text-learnMore,
    .text-learnMore-before {
        font-size: 40px;
        margin-bottom: 0;
        height: 44px;
    }

    .text-learnMore {
        -webkit-text-stroke: 6px #221F50;
    }

    .text-learnMore-before {}
}

.text-story,
.text-story-before {
    font-family: 'Dongo Regular', sans-serif;
    font-size: 30px;
    height: 33px;
}

.text-story {
    -webkit-text-stroke: 4px #221F50;
    color: #fff;

}

.text-story-before {
    background-image: linear-gradient(180.99deg, #B7BDE2 25.56%, #9195F0 83.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (min-width: 1024px) {

    .text-story,
    .text-story-before {
        font-size: 54px;
        padding-top: 0rem;
        height: 59px;
    }

    .text-story {
        -webkit-text-stroke: 8px #221F50;
    }
}


.text-team,
.text-team-before {
    font-family: 'Dongo Regular', sans-serif;
    font-size: 30px;
    height: 33px;
    margin-top: 1.5rem;
}

.text-team {
    -webkit-text-stroke: 4px #221F50;
    color: #fff;

}

.text-team-before {
    background-image: linear-gradient(180.99deg, #B7BDE2 25.56%, #9195F0 83.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (min-width: 1024px) {

    .text-team,
    .text-team-before {
        font-size: 84px;
        margin-top: 0rem;
        height: 84px;
    }

    .text-team {
        -webkit-text-stroke: 12px #221F50;
    }

    .text-team-before {}
}

.textUserTable {
    background: linear-gradient(90deg, #4D66FF 0%, #FFC200 64.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.stt_1 {
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
        , #EEB443;

}

.stt_2 {
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
        , #8F93A1;

}

.stt_3 {
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
        , #FA6E32;
}

.listTeamImg {
    height: 11rem;
}

@media (min-width: 1024px) {
    .listTeamImg {
        height: 247px;
        /* position: relative; */
    }

    .listTeamImg img {
        /* position: absolute;
        top: 0;
        left: 0; */
    }

}

.text-menu-bottom {
    font-family: 'Dongo Regular', sans-serif;
    font-size: 12.71px;
    -webkit-text-fill-color: black;
}

@media (min-width: 1024px) {
    .text-menu-bottom {
        font-size: 16px;
    }
}


@keyframes fallAndBounce {
    0% {
        transform: translateY(-100%);
        animation-timing-function: ease-in;
    }

    20% {
        transform: translateY(0%);
        animation-timing-function: ease-out;
    }

    35% {
        transform: translateY(-21%);
        animation-timing-function: ease-in;
    }

    50% {
        transform: translateY(0%);
        animation-timing-function: ease-out;
    }

    60% {
        transform: translateY(-7%);
        animation-timing-function: ease-in;
    }

    70% {
        transform: translateY(0%);
        animation-timing-function: ease-out;
    }

    80% {
        transform: translateY(-2%);
        animation-timing-function: ease-in;
    }

    90% {
        transform: translateY(0%);
        animation-timing-function: ease-out;
    }

    95% {
        transform: translateY(-1%);
        animation-timing-function: ease-in;
    }

    100% {
        transform: translateY(0%);
    }
}


.falling-animation {
    /* animation: fallAndBounce 1s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
    animation: fallAndBounce 3s;
    animation-timing-function: ease;
    /* position: relative; */
}

.imgDog {
    /* width: 38vw; */
}

@media (min-width: 1024px) {
    .imgDog {
        width: 38vw;
    }
}

.bg-menuPage {
    background-image: linear-gradient(180.99deg, #B7BDE2 25.56%, #9195F0 83.65%);
}

.btn-menu {
    background-image: linear-gradient(270deg, #4D66FF 0%, #4EBBF8 100%);
    box-shadow: 0px 15.9984px 27.4259px rgba(0, 0, 0, 0.1);
}

.btn-menu:hover {
    background: #FF7FEB;
}

.btn-play {
    background: #4D66FF;
}

.btn-play:hover {
    background: #1730bf;
}

.btn-buyBlocky {
    background-image: linear-gradient(270deg, #3452FE 0%, #FF68C3 100%);
}

.btn-buyBlocky:hover {
    background-image: linear-gradient(270deg, #FF68C3 0%, #3452FE 100%);
}

.btn-buyNow {
    background: linear-gradient(270deg, #7E68FF 0%, #3452FE 99.99%, #4EBBF8 100%);
    box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.1);
}

.btn-buyNow:hover {
    background: #FF7FEB;
}

.border-white-translucent {
    border-color: rgba(255, 255, 255, 0.47);
}


.user-drag-none {
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
}

.top-inherit {
    top: inherit
}

.left-inherit {
    left: inherit
}


.bg-story {
    width: 125vw;
    position: absolute;
    left: -23vw;
    bottom: 0;
    max-width: inherit;
}

@media (min-width: 1024px) {
    .bg-story {
        width: 862px;
        position: absolute;
        right: -250px;
        left: inherit;
    }
}

.dogAboutUs {
    border-radius: 38px;
}

.textSection {
    font-size: 14px;
    font-weight: 300;
    font-family: "IBM Plex Sans", sans-serif;
}

.process {
    width: 100%;
    background: #CFE4FF;
    border-radius: 36px;
}

.bar {
    width: 80%;
    height: 32px;
    background: linear-gradient(270deg, #9195F0 0%, #675EED 100%);
    ;
    border-radius: 36px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 10px;
}

@media (max-width: 1024px) {
    .textSection-3 {
        margin-bottom: 50vw;
    }
}



.textSmallSection {
    font-size: 14px;
}

.listMenu {
    left: 2.25rem;
    top: 7rem;
}

.listMenu li {
    height: 65px;
}

@media (min-width: 1024px) {
    .listMenu {
        left: inherit;
        top: 11rem;
    }

    .listMenu li {
        height: fit-content;
    }

}

.listSocial {
    top: 24rem;
    /* display: none; */
}

@media (min-width: 1024px) {
    .listSocial {
        top: inherit;
        top: 36rem;
    }

    .listSocial_list {
        max-width: 27vw;
    }
}

.listFooterMenu {}

@media (min-width: 1024px) {
    .listFooterMenu {
        top: 43rem
    }
}

.textBlockchainWrapper {}

@media (min-width: 1024px) {
    .textBlockchainWrapper {
        top: 40rem;
    }
}


.text-staking,
.text-staking-before {
    font-family: 'Dongo Regular', sans-serif;
    font-size: 30px;
    height: 33px;
}

.text-staking {
    -webkit-text-stroke: 4px #221F50;
    color: #fff;

}

.text-staking-before {
    background-image: linear-gradient(180.99deg, #B7BDE2 25.56%, #9195F0 83.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-roadmap {
    background: linear-gradient(to bottom, #B8B7E2, #9195F0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 4px #221F50;
}

@media (min-width: 1024px) {

    .text-staking,
    .text-staking-before {
        font-size: 104px;
        padding-top: 0rem;
        height: 114px;
    }

    .text-staking {
        -webkit-text-stroke: 12px #221F50;
    }
}

.staking_top {
    /* width: 29rem; */
}

@media (min-width: 1024px) {
    .staking_top {
        /* width: 29rem; */
    }
}


.stakingItem {
    box-shadow: 0px 14px 24px rgba(144, 148, 161, 0.13);
}

.stakingItem .top p {
    color: #9094A1;
    text-align: center;
    letter-spacing: -0.04em;
    text-transform: capitalize;
}

.stakingItem .bottom p {
    font-family: 'Dongo Regular', sans-serif;
    font-size: 30px;
    text-align: center;
}

@media (min-width: 1024px) {
    .stakingItem {
        font-size: 20px;
    }
}


.text-system,
.text-system-before {
    font-family: 'Dongo Regular', sans-serif;
    font-size: 30px;
    height: 33px;
}

.text-system {
    -webkit-text-stroke: 4px #221F50;

    color: #fff;

}

.text-system-before {
    background-image: linear-gradient(180.99deg, #B7BDE2 25.56%, #9195F0 83.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (min-width: 1024px) {

    .text-system,
    .text-system-before {
        font-size: 54px;
        padding-top: 0rem;
        height: 57px;
    }

    .text-system {
        -webkit-text-stroke: 8px #221F50;
    }
}

/* Custom media query to ensure the override works */
@media (min-width: 1024px) {
    .lg-max-w-1122 {
        max-width: 1122px;
    }

    .lg-translate-x-0 {
        transform: translateX(0);
    }

    .lg-top-inherit {
        top: inherit
    }

    .lg-left-inherit {
        left: inherit
    }

    .lg-bg-top-about {
        /* height: 414px; */
        height: 29vw;
    }

    .lg-width-100-minus-w80 {
        width: calc(100% - 20rem);
    }

    .dogAboutUs {
        border-radius: 68px;
    }

    .textSection {
        font-size: 24px;
    }

    .textSmallSection {
        font-size: 20px;
    }

}

.section-token {
    background-size: auto 107.11%;
    background-position: center top;
}

@media (max-width: 768px) {
    .text-dongo {
        -webkit-text-stroke: 5px #221F50;

    }
}

@media (min-width: 1540px) {
    .section-token {
        background-size: cover;
        background-position: center center;
    }
}

.slick-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0;
}

.slider-container-raffles {
    overflow: hidden;
    width: 100%;
    max-width: 100vw;
    box-sizing: border-box;
    margin: 0 auto;
}

/* .slider-container-raffles .slick-arrow::before {
    display: none;
} */

/* .slider-container-raffles .slick-track {
    transform: translateX(0) !important; 
  } */
.slider-container-raffles .slick-prev,
.slick-next {
    top: 30%;
}

.slider-container-raffles .slick-prev {
    left: -117px;
}

.slider-container-raffles .slick-next {
    right: 0px;
}

.bg-gradient-1 {
    background: linear-gradient(180deg, #B8B7E2 0%, #9195F0 79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient-2 {
    background: linear-gradient(180.99deg, #FFCB47 25.56%, #E04716 83.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient-3 {
    background: linear-gradient(180deg, #E2B7B7 0%, #F091CA 79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient-divider {
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.shadow-1 {
    box-shadow:
        0px 1px 2px 0px #4C66FF47,
        0px 4px 4px 0px #4C66FF3D,
        0px 9px 5px 0px #4C66FF24,
        0px 16px 6px 0px #4C66FF0A,
        0px 25px 7px 0px #4C66FF03;
}

.shadow-2 {
    box-shadow: 0px 4px 14px 0px #D96EFF54;
}


ul li {
    list-style-type: none;
}

.custom-menu>ul li::before {
    content: "•";
    color: black;
    font-size: 12px;
    margin-right: 5px;
}
.justify-content-start {
    justify-content: start;
}